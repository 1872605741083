import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function Home() {

  return <Layout>
    <h1>Uppstyrda processer <br /><em>för</em> storslagna
    <span className="highlight yellow inline-block">drömmar</span></h1>
    <p className="heading-intro">Kul att du är här!</p>
    <h2>Jag hjälper till att skapa engagerade team som vågar tro på att de kan
    nå och överträffa högt uppsatta mål.</h2>
    <p>Jag heter Sofia Sundström och har jobbat med att leda kreativa team och
    komplexa projekt i 15 år. Ur mina erfarenheter av agila processer,
    samskapande och användar­centrerad design har jag utvecklat <Link to="/bubbles-of-bliss/">Bubbles of Bliss</Link> –
    en metod för processdesign som grundar sig i att välmående team är mer
    effektiva och skapar bättre resultat.</p>

<p>Design, innovation, konst och filosofi ligger mig varmt om hjärtat och jag
söker ständigt nya svar på hur vi kan arbeta för mer hållbara människor, ett
mer hållbart samhälle och en mer hållbar planet.</p>

<p><Link to="/sofia-sundstrom/">Läs mer om mig, mina erfarenheter och tidigare projekt.</Link></p>

<section className="factbox-container">
  <div className="factbox">
    <h2>Jag hjälper dig med:</h2>
    <ul className="columns">
      <li>Projekt- och processledning</li>
      <li>Strategi- och konceptutveckling</li>
      <li>Nuläges- och behovsanalys</li>
      <li>Tjänstedesign</li>
      <li>Workshops</li>
      <li>Research och djupintervjuer</li>
      <li>Coaching</li>
      <li>Föreläsningar</li>
    </ul>
  </div>
</section>
  </Layout>
}
